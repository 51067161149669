<template>
  <div class="main-body">
    <el-date-picker
      v-model="dateOfDay"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择日期"
      style="margin-bottom: 10px"
      @change="dateOfDayChange"
    >
    </el-date-picker>
    <div class="main">
      <div
        :class="item.fields.length > 7 ? 'item1' : 'item'"
        v-for="(item, index) in homeFieldsList"
        :key="index"
      >
        <div class="title">{{ item.title }}</div>
        <div class="card-list">
          <el-card
            class="box-card m-right"
            v-for="(field, i) in item.fields"
            :key="i"
            :class="getColors(i)"
          >
            <div class="label">{{ field.label }}</div>
            <div class="value">
              {{
                homeData[field.field] == undefined ? 0 : homeData[field.field]
              }}
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataUtil from "../utils/config/dataUtil";
import http from "../api/home";
const colors = [
  "borderColor1",
  "borderColor2",
  "borderColor3",
  "borderColor4",
  "borderColor5",
  "borderColor6",
];
export default {
  data() {
    return {
      homeFieldsList: [
        {
          title: "代收",
          fields: [
            {
              label: "代收单数",
              field: "rCnt",
            },
            { label: "代收成功单数", field: "rSuccessCnt" },
            { label: "代收成功金额", field: "rSuccessAmt" },
          ],
        },
        {
          title: "代付",
          fields: [
            {
              label: "代付单数",
              field: "wCnt",
            },
            { label: "代付金额", field: "wAmt" },
            { label: "代付成功单数", field: "wSuccessCnt" },
            { label: "代付成功金额", field: "wSuccessAmt" },
            { label: "代付失败单数", field: "wFailCnt" },
            { label: "代付失败金额", field: "wFailAmt" },
          ],
        },
        {
          title: "审核中/代付中",
          fields: [
            {
              label: "代付中单数",
              field: "wPendingCnt",
            },
            { label: "代付中金额", field: "wPendingAmt" },
            { label: "代付失败待审核单数", field: "wCheckCnt" },
            { label: "代付失败待审核金额", field: "wCheckAmt" },
          ],
        },
        {
          title: "承兑",
          fields: [
            {
              label: "承兑单数",
              field: "hCnt",
            },
            { label: "承兑金额", field: "hAmt" },
            { label: "承兑成功单数", field: "hSuccessCnt" },
            { label: "承兑成功金额", field: "hSuccessAmt" },
            { label: "承兑失败单数", field: "hFailCnt" },
            { label: "承兑失败金额", field: "hFailAmt" },
            { label: "承兑中单数", field: "hPendingCnt" },
            { label: "承兑中金额", field: "hPendingAmt" },
          ],
        },
      ],
      dateOfDay: new Date(),
      homeData: {},
      timer: null,
    };
  },
  methods: {
    getColors(index) {
      if (index > 5) {
        return colors[index % 5];
      }
      return colors[index];
    },
    dateOfDayChange(value) {
      if (value != null) {
        this.dateOfDay = value;
        this.getHomeData();
      }
    },
    getHomeData() {
      http
        .getHomeDataApi({
          content: DataUtil.dateTransformYMD(this.dateOfDay),
        })
        .then((res) => {
          if (res.code == "0000") {
            this.homeData = res.data;
            console.log(this.homeData);
            this.repeatedRequest(res.data.expireSeconds);
          } else {
            this.$message.error(res.message);
          }
        });
    },
    format(num) {
      if (this.isEmpty(num)) {
        num = 0;
      }
      return num.toFixed(2);
    },
    /**
     * 再次请求
     */
    repeatedRequest(time) {
      if (this.timer != null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getHomeData();
      }, time * 1000);
    },
  },
  mounted: function () {
    this.getHomeData();
    console.log(localStorage.getItem("lange"));
  },
  created: function () {},
  components: {},
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item {
  width: 33%;
  background: #9993;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
}
.item1 {
  width: 100%;
  background: #9993;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.right {
  width: 35%;
  background: #9993;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
}
.card-list1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.card-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.title {
  line-height: 50px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  padding-left: 30px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}
.box-card {
  width: 150px;
  height: 180px;
  box-sizing: border-box;
  text-align: center;
}
.m-right {
  margin-bottom: 10px;
  margin-right: 10px;
}
.box-card ::v-deep .el-card__body {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 180px;
  box-sizing: border-box;
}
.value {
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  word-wrap: break-word;
}
.label {
  font-size: 18px;
  font-weight: 600;
  color: #6a6a6a;
}

.borderColor5 {
  border-top: 5px solid #04f2d7;
}
.borderColor1 {
  border-top: 5px solid #9666fa;
}
.borderColor2 {
  border-top: 5px solid #fe345e;
}
.borderColor3 {
  border-top: 5px solid #ffbf00;
}
.borderColor4 {
  border-top: 5px solid #9666fa;
}
.borderColor6 {
  border-top: 5px solid #fe345e;
}
</style>
